import api from '@/http'

export default {
  // 厂家客户订单记录
  facPayOrderList: (data) => {
    return api({
      url: '/Payment/PayOrderList',
      method: 'post',
      data
    })
  },
  // 订单收款确认
  confirmPayOrder: (data) => {
    return api({
      url: '/Payment/confirmPayOrder',
      method: 'post',
      data
    })
  },

  // 厂家收款列表
  facPaymentList: (data) => {
    return api({
      url: '/PaymentFactory/PaymentList',
      method: 'post',
      data
    })
  },
  // 厂家收款概况
  facPaymentOverview: (data) => {
    return api({
      url: '/PaymentFactory/Overview',
      method: 'post',
      data
    })
  },
  // 店家收款列表
  shopPaymentList: (data) => {
    return api({
      url: '/PaymentShop/PaymentList',
      method: 'post',
      data
    })
  },
  // 店家收款概况
  shopPaymentOverview: (data) => {
    return api({
      url: '/PaymentShop/Overview',
      method: 'post',
      data
    })
  },
}
