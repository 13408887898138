import { createRouter, createWebHistory } from 'vue-router'
import layout from '@/pages/layout/index'

const importPage = view => () => import(`../pages/${view}.vue`)
// 公共路由
export const constantRoutes = [
  {
    path: '/404',
    component: importPage('error/404'),
    hidden: true
  },
  {
    path: '/401',
    component: importPage('error/401'),
    hidden: true
  },
  {
    path: '/login',
    component: importPage('login'),
    hidden: true
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes: constantRoutes
})

export default router

export const role_router = {
  type_1: [
    {
      path: '/',
      component: layout,
      redirect: '/chat',
      children: [
        {
          path: 'chat',
          name: 'chat',
          component: importPage('chatPage'),
          meta: {
            title: '客户交流',
            icon: 'ChatDotRound'
          }
        }
      ]
    },
    {
      path: '/order',
      component: layout,
      redirect: '/order/orders',
      meta: {
        title: '订单管理'
      },
      children: [
        {
          path: 'list',
          component: importPage('orders/factory/list'),
          meta: {
            title: '订单列表',
            icon: 'List'
          }
        }
      ]
    },
    {
      path: '/customer',
      component: layout,
      meta: {
        title: '客户管理',
        icon: 'Shop'
      },
      children: [
        {
          path: 'list',
          component: importPage('customer/list'),
          meta: {
            title: '客户列表'
          }
        },
        {
          path: 'map',
          component: importPage('customer/map'),
          meta: {
            title: '平台客户'
          }
        },
      ]
    },
    {
      path: '/finance',
      component: layout,
      meta: {
        title: '财务管理',
        icon: 'Money'
      },
      children: [
        {
          path: 'customer',
          name: 'paymentCustomer',
          component: importPage('finance/factory/customer'),
          meta: {
            title: '客户收款'
          }
        },
        {
          path: 'payment',
          name: 'payment',
          component: importPage('finance/factory/payment'),
          meta: {
            title: '收款记录'
          }
        }
      ]
    },
    {
      path: '/deposit',
      component: layout,
      meta: {
        title: '预存款',
        icon: 'WalletFilled'
      },
      children: [
        {
          path: 'customer',
          component: importPage('deposit/factory/customer'),
          meta: {
            title: '预存客户'
          }
        },
        {
          path: 'charge',
          component: importPage('deposit/factory/chargeRecord'),
          meta: {
            title: '预存记录'
          }
        },
        {
          path: 'activity',
          component: importPage('deposit/factory/activity'),
          meta: {
            title: '预存活动'
          }
        },
        // {
        //   path: 'consume',
        //   component: importPage('deposit/factory/consumeRecord'),
        //   meta: {
        //     title: '消费记录'
        //   }
        // }
      ]
    },
    {
      path: '/coupon',
      component: layout,
      meta: {
        title: '优惠券',
        icon: 'Discount'
      },
      children: [
        {
          path: 'manage',
          component: importPage('coupon/factory/manage'),
          meta: {
            title: '优惠券列表'
          }
        },
        {
          path: 'record',
          component: importPage('coupon/factory/record'),
          meta: {
            title: '领取记录'
          }
        }
      ]
    },
    {
      path: '/payment',
      hidden: true,
      component: layout,
      children: [
        {
          path: 'order/:id',
          name: 'payOrder',
          component: importPage('payment/payOrder'),
          meta: {
            title: '付款订单'
          }
        }
      ]
    },
    {
      path: '/ship',
      component: layout,
      meta: {
        title: '物流配送',
        icon: 'Ship'
      },
      children: [
        {
          path: 'shipAll',
          component: importPage('ship/allOrder'),
          meta: {
            title: '订单列表'
          }
        },
        {
          path: 'shipQueue',
          component: importPage('ship/shipQueue'),
          meta: {
            title: '规划配送'
          }
        },
        {
          path: 'shipCustomer',
          component: importPage('ship/shipCustomer'),
          meta: {
            title: '客户管理'
          }
        },
        {
          path: 'shipDriver',
          component: importPage('ship/shipDriver'),
          meta: {
            title: '司机管理'
          }
        },
        {
          path: 'shipDriverYJ',
          component: importPage('ship/shipDriveryj'),
          meta: {
            title: '司机业绩'
          }
        }
      ]
    },
    // {
    //   path: '/my',
    //   component: layout,
    //   children: [
    //     {
    //       path: 'shop',
    //       component: importPage('shop/myShop'),
    //       meta: {
    //         title: '我的主页'
    //       }
    //     }
    //   ]
    // },
    {
      path: '/shopAction',
      hidden: true,
      component: layout,
      redirect: '/shopAction/perfect',
      children: [
        {
          path: 'perfect',
          component: importPage('shop/action'),
          meta: {
            title: '设置'
          }
        }
      ]
    },
    {
      path: '/setting',
      component: layout,
      children: [
        {
          path: 'center',
          component: importPage('profile'),
          meta: {
            title: '设置中心',
            icon: 'Setting'
          }
        }
      ]
    }
  ],
  type_2: [
    {
      path: '/',
      component: layout,
      redirect: '/chat',
      children: [
        {
          path: 'chat',
          name: 'chat',
          component: importPage('chatPage'),
          meta: {
            title: '厂家交流',
            icon: 'ChatDotRound'
          }
        }
      ]
    },
    // {
    //   path: '/home',
    //   component: layout,
    //   redirect: '/home/index',
    //   children: [
    //     {
    //       path: 'index',
    //       component: importPage('index'),
    //       meta: {
    //         title: '首页',
    //         icon: 'HomeFilled'
    //       }
    //     }
    //   ]
    // },
    {
      path: '/order',
      component: layout,
      redirect: '/order/shop',
      children: [
        {
          path: 'shop',
          component: importPage('orders/shop/list'),
          meta: {
            title: '订单管理',
            icon: 'List'
          }
        }
      ]
    },
    {
      path: '/finance',
      component: layout,
      meta: {
        title: '财务管理',
        icon: 'Money'
      },
      children: [
        {
          path: 'payment',
          name: 'payment',
          component: importPage('finance/shop/payment'),
          meta: {
            title: '付款记录'
          }
        },
        {
          path: 'voucher',
          name: 'financeVoucher',
          component: importPage('finance/shop/voucher'),
          meta: {
            title: '抵用金'
          }
        },
        {
          path: 'prepaid',
          name: 'buyerPrepaid',
          component: importPage('deposit/shop/main'),
          meta: {
            title: '预存款'
          }
        },
        {
          path: 'coupon',
          name: 'buyerCoupon',
          component: importPage('coupon/shop/main'),
          meta: {
            title: '优惠券'
          }
        }
      ]
    },
    // {
    //   path: '/factory',
    //   component: layout,
    //   meta: {
    //     title: '合作厂家',
    //     icon: 'Shop'
    //   },
    //   children: [
    //     {
    //       path: 'my',
    //       name: 'myFactory',
    //       component: importPage('factory/list'),
    //       meta: {
    //         title: '我的厂家'
    //       }
    //     },
    //     {
    //       path: 'map',
    //       name: 'factoryMap',
    //       component: importPage('factory/map'),
    //       meta: {
    //         title: '厂家分布'
    //       }
    //     }
    //   ]
    // },
    {
      path: '/business',
      component: layout,
      meta: {
        title: '商机合作',
        icon: 'WalletFilled'
      },
      children: [
        {
          path: 'my',
          name: 'businessMy',
          component: importPage('business/my'),
          meta: {
            title: '我的商机'
          }
        },
        {
          path: 'partner',
          name: 'businessPartner',
          component: importPage('business/partner'),
          meta: {
            title: '合作对象'
          }
        }
      ]
    },
    {
      path: '/shop/detail',
      component: layout,
      hidden: true,
      children: [
        {
          path: 'op/:id',
          name: 'shopDetail',
          component: importPage('shop/detail'),
          meta: {
            title: '详情'
          }
        }
      ]
    },
    {
      path: '/master',
      component: layout,
      children: [
        {
          path: 'lobby',
          component: importPage('master/index'),
          meta: {
            title: '需求大厅',
            icon: 'HelpFilled'
          }
        },
        {
          path: 'detail/:id',
          name: 'masterDetail',
          component: importPage('master/detail'),
          hidden: true,
          meta: {
            title: '详情'
          }
        }
      ]
    },
    {
      path: '/mini',
      component: layout,
      children: [
        {
          path: 'program',
          component: importPage('miniprogram/show'),
          meta: {
            title: '小程序',
            icon: 'Promotion'
          }
        }
      ]
    },
    {
      path: '/setting',
      component: layout,
      children: [
        {
          path: 'center',
          component: importPage('profile'),
          meta: {
            title: '设置中心',
            icon: 'Setting'
          }
        }
      ]
    }
  ],
  type_8: [
    {
      path: '/',
      component: layout,
      redirect: '/miniProgram',
      children: [
        {
          path: 'miniProgram',
          component: importPage('miniprogram/show'),
          meta: {
            title: '小程序'
          }
        }
      ]
    },
    {
      path: '/setting',
      component: layout,
      children: [
        {
          path: 'center',
          component: importPage('profile'),
          meta: {
            title: '设置中心'
          }
        }
      ]
    },
  ],
  type_9: [
    {
      path: '/',
      component: layout,
      redirect: '/user',
      children: [
        {
          path: 'user',
          component: importPage('user/index'),
          meta: {
            title: '用户管理'
          }
        }
      ]
    },
    {
      path: '/setting',
      component: layout,
      children: [
        {
          path: 'center',
          component: importPage('profile'),
          meta: {
            title: '设置中心'
          }
        }
      ]
    }
  ],
  type_3: [
    {
      path: '/',
      component: layout,
      redirect: '/shipAll',
      meta: {
        title: '物流配送'
      },
      children: [
        {
          path: 'shipAll',
          component: importPage('ship/allOrder'),
          meta: {
            title: '订单列表'
          }
        },
        {
          path: 'shipQueue',
          component: importPage('ship/shipQueue'),
          meta: {
            title: '规划配送'
          }
        },
        {
          path: 'shipCustomer',
          component: importPage('ship/shipCustomer'),
          meta: {
            title: '客户管理'
          }
        },
        {
          path: 'shipDriver',
          component: importPage('ship/shipDriver'),
          meta: {
            title: '司机管理'
          }
        },
        {
          path: 'shipDriverYJ',
          component: importPage('ship/shipDriveryj'),
          meta: {
            title: '司机业绩'
          }
        }
      ]
    },
    {
      path: '/setting',
      component: layout,
      children: [
        {
          path: 'center',
          component: importPage('profile'),
          meta: {
            title: '设置中心'
          }
        }
      ]
    }
  ]
}
