import { readLocal, removeLocal, saveLocal } from '@/assets/js/common.ts'
import api from '@/api'
import { encrypt } from '@/assets/js/jsencrypt'

let token = readLocal('USER', 'TOKEN') || null
export default {
  namespaced: true,
  state: {
    token: token,
    userInfo: {},
    isLogin: false,
    account: readLocal('USER', 'ACCOUNT') || '',
    password: readLocal('USER', 'PASSWORD') || ''
  },
  mutations: {
    SET_USER_TOKEN: (state, data) => {
      state.token = data
      saveLocal('USER', 'TOKEN', data)
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = {
        ...state.userInfo,
        ...userInfo
      }
      state.isLogin = true
    },
    SET_USER_PASSWORD: (state, form) => {
      if (form) {
        state.password = encrypt(form.Password)
        state.account = form.Name
      } else {
        state.password = ''
        state.account = ''
      }
      saveLocal('USER', 'ACCOUNT', state.account)
      saveLocal('USER', 'PASSWORD', state.password)
    },
    REMOVE_TOKEN: (state) => {
      state.token = ''
      removeLocal('USER', 'TOKEN')
    }
  },
  actions: {
    getUserInfo ({ commit }) {
      return new Promise(resolve => {
        api.getUserInfo().then(res => {
          commit('SET_USER_INFO', res)
          resolve(res)
        })
      })
    }
  }
}
