<template>
  <div class="layout-wrap" @drop="handleDrop" @dragover="handleDragOver">
    <div class="side-bar">
      <div class="logo">
        <img src="/image/logo.png" alt="">
        <span>六六广告</span>
      </div>
      <div class="menu-wrap">
        <el-scrollbar max-height="100%">
          <el-menu active-text-color="#fff" background-color="#171717" class="el-menu-vertical-demo" :default-active="routerKey" text-color="#999" router unique-opened>
            <template v-for="(group,gi) in list">
              <template v-if="group.children.length>1">
                <el-sub-menu :key="group.path" :index="`g_${gi}`">
                  <template #title>
                    <el-icon>
                      <component :is="group.meta.icon" />
                    </el-icon>
                    <span>{{ group.meta.title }}</span>
                  </template>
                  <el-menu-item v-for="item in group.children" :key="`m_${item.path}`" :index="`${group.path}/${item.path}`">
                    {{ item.meta.title }}
                  </el-menu-item>
                </el-sub-menu>
              </template>
              <template v-else>
                <el-menu-item v-for="item in group.children" :key="`n_${item.path}`" :index="`${(group.path==='/'?'':group.path)}/${item.path}`">
                  <template #title>
                    <el-icon>
                      <component :is="item.meta.icon" />
                    </el-icon>
                    <span>{{ item.meta.title }}</span>
                  </template>
                </el-menu-item>
              </template>
            </template>
          </el-menu>
        </el-scrollbar>
      </div>
    </div>
    <div class="layout-main">
      <div class="lm-hd">
        <div class="lm-local">当前位置：<span>{{ $route.meta.title }}</span></div>
        <user-info />
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
import userInfo from "@/components/userInfo";
import { role_router } from '@/router'
import { mapState } from "vuex";

export default {
  name: 'layout-wrap',
  components: {
    userInfo
  },
  data () {
    return {
      routerKey: '/',
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo
    }),
    list () {
      if (this.userInfo.RoleType) {
        return role_router['type_' + this.userInfo.RoleType].map(o => {
          o.children = o.children.filter(m => !m.hidden)
          return o
        }).filter(n => !n.hidden)
      }
      return []
    }
  },
  methods: {
    handleDragOver (event) {
      event.preventDefault();
    },
    handleDrop (event) {
      event.preventDefault();
    }
  },
  watch: {
    $route: {
      handler (e) {
        this.routerKey = e.path
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
.layout-wrap {
  position: relative;
  padding-left: 160px;
  height: 100vh;

  .side-bar {
    width: 160px;
    background: #171717;
    box-shadow: 1px 0 4px rgba(0, 21, 41, 0.08);
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    padding-right: 20px;

    .logo {
      padding: 10px 0;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 50px;
      }

      span {
        font-size: 24px;
        font-weight: bold;
        font-family: cursive;
        color: white;
      }
    }
  }

  .layout-main {
    position: relative;
    z-index: 2;
    background: #f1f7fa;
    border-radius: 20px 0 0 20px;
    height: 100%;

    .lm-hd {
      background: #fbfdfe;
      height: 50px;
      border-radius: 20px 0 0 0;
      display: flex;
      flex-direction: row;
      padding-left: 30px;
      justify-content: space-between;
      align-items: center;
    }

    .lm-local {
      font-size: 12px;
      color: #999;

      span {
        font-size: 14px;
        color: #145dff;
      }
    }
  }
}

.menu-wrap {
  height: calc(100vh - 70px);
  background: url(../../assets/image/m_bg.png) no-repeat center bottom;
  background-size: 100% auto;
}
</style>
