import api from '@/http'

export default {
  // 获取联系人
  getRoomList: (data) => {
    return api({
      url: '/Chat/RoomList',
      method: 'post',
      data: data
    })
  },
  // 获取联系人
  getRoomList_Shop: (data) => {
    return api({
      url: '/Chat/RoomList_Shop',
      method: 'post',
      data: data
    })
  },
  // 创建聊天
  createChat: (id) => {
    return api({
      url: '/Chat/CreateChat',
      method: 'post',
      data: {
        UserIds: id
      }
    })
  },
  // 聊天记录
  getChatHistory: (id, page) => {
    return api({
      url: '/Chat/MessageList',
      method: 'post',
      data: {
        Id: id,
        paging: {
          pageSize: 20,
          pageNum: page
        }
      }
    })
  },
  // 获取联系人
  hasFile: (data) => {
    return api({
      url: '/Main/HasFile',
      method: 'post',
      data: data
    })
  },
  // 获取联系人
  saveFile: (data) => {
    return api({
      url: '/Main/SaveFile',
      method: 'post',
      data: data
    })
  },
  // 获取联系人
  getFinanceInfo: (data) => {
    return api({
      url: '/Payment/FinanceInfo',
      method: 'post',
      data: data
    })
  },
}
