import api from '@/http'

export default {
  // 产品类型
  showCategoryList: () => {
    return api({
      url: '/ShowCategory/List',
      method: 'post',
      data: '{}'
    })
  },
  showCategorySort: (data) => {
    return api({
      url: '/ShowCategory/Sort',
      method: 'post',
      data
    })
  },
  showCategoryAdd: (data) => {
    return api({
      url: '/ShowCategory/Insert',
      method: 'post',
      data
    })
  },
  showCategoryDel: (id) => {
    return api({
      url: '/ShowCategory/Delete',
      method: 'post',
      data: {
        Id: id
      }
    })
  },
  // 单位产品提交
  addShowCase: (data) => {
    return api({
      url: '/ShowCase/Insert',
      method: 'post',
      data
    })
  },
  // 单位产品删除
  deleteShowCase: (id) => {
    return api({
      url: '/ShowCase/Delete',
      method: 'post',
      data: {
        Id: id
      }
    })
  },
  // 产品列表
  showCaseList: () => {
    return api({
      url: '/ShowCase/List',
      method: 'post',
      data: '{}'
    })
  },
}
