import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import ElementPlus from 'element-plus'
import locale from 'element-plus/lib/locale/lang/zh-cn';
import date from '@/assets/js/date.js'
import '@/assets/css/reset.scss'
import 'element-plus/theme-chalk/index.css'
import '@/assets/css/mixin.scss'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import './permission' // permission control
const app = createApp(App)
for (const [key, comp] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, comp)
}
app.use(date)
app.use(store)
app.use(router)

app.use(ElementPlus, { locale, size: 'default', zIndex: 3000 });
app.mount('#app')
