import api from '@/http'

export default {
  // 添加优惠券
  addCoupon: (data) => {
    return api({
      url: '/Coupon/Insert',
      method: 'post',
      data
    })
  },
  // 下架优惠券
  takeOffCoupon: (id) => {
    return api({
      url: '/Coupon/TakeOff',
      method: 'post',
      data: { Id: id }
    })
  },
  // 上架优惠券
  takeOnCoupon: (id) => {
    return api({
      url: '/Coupon/TakeOn',
      method: 'post',
      data: { Id: id }
    })
  },
  // 优惠券管理列表
  getCoupon: (data) => {
    return api({
      url: '/Coupon/List',
      method: 'post',
      data
    })
  },
  // 优惠券列表
  getCouponList: (data) => {
    return api({
      url: '/Coupon/CouponList',
      method: 'post',
      data
    })
  },
  //领取优惠券
  ClaimCoupon: (id) => {
    return api({
      url: '/Coupon/Claim',
      method: 'post',
      data: { Id: id }
    })
  },
  // 客户的领取列表
  coupon_ShopClaimList: (data) => {
    return api({
      url: '/CouponShop/List',
      method: 'post',
      data
    })
  },
  // 厂家的领取列表
  coupon_FactoryClaimList: (data) => {
    return api({
      url: '/CouponFactory/List',
      method: 'post',
      data
    })
  },
  // 店家的统计概览
  coupon_ShopOverview: (data) => {
    return api({
      url: '/CouponShop/Overview',
      method: 'post',
      data
    })
  },
  // 厂家的统计概览
  coupon_FactoryOverview: (data) => {
    return api({
      url: '/CouponFactory/Overview',
      method: 'post',
      data
    })
  },
  // 厂家的优惠券列表
  getFactoryCouponList: (data) => {
    return api({
      url: '/CouponShop/CliamCouponList',
      method: 'post',
      data
    })
  },
  // 领取厂家的优惠券
  claimFactoryCoupon: (data) => {
    return api({
      url: '/CouponShop/Claim',
      method: 'post',
      data
    })
  },
}
