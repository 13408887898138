<template>
  <div class="user-info">
    <div class="ui-info">你好，<span class="name" @click="toProfile">{{ user.CompanyName }}</span> | <span class="logout" @click="toLogout">退出</span>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'user-info',
  computed: {
    ...mapState({
      user: state => state.user.userInfo
    })
  },
  methods: {
    ...mapMutations({
      removeToken: 'user/REMOVE_TOKEN',
    }),
    toLogout () {
      this.$confirm('确定退出吗？', '提示', {
        confirmButtonText: '退出',
        cancelButtonText: '再想想',
        type: 'warning'
      }).then(() => {
        this.removeToken()
        location.href = '/login'
      }).catch(() => {
      })
    },
    toProfile () {
      this.$router.push('/setting/center')
    }
  }
}
</script>

<style scoped lang="scss">
.user-info {
  .ui-info {
    text-align: right;
    font-size: 15px;
    color: #999;
    line-height: 30px;
    padding: 10px 15px;

    .name {
      color: #145dff;
      cursor: pointer;
      margin-right: 5px;
    }

    .logout {
      margin-left: 5px;
      color: #999;
      cursor: pointer;
    }
  }
}
</style>
