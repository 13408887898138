import api from '@/http'

export default {
  // 获取oss
  getOssKey: () => {
    return api({
      url: '/Main/Oss',
      method: 'post',
      data: '{}'
    })
  },
  // 服务项目
  getCategory: () => {
    return api({
      url: '/Category/List',
      method: 'post',
      data: '{}'
    })
  },
  // 服务项目
  post: (url, method, data) => {
    return api({
      url: url,
      method: method,
      data: data
    })
  },
}
