<template>
  <div class="chat-new-msg" @click="toSend">
    <div v-if="receive">
      {{ name }}：<span v-html="contentMsg"></span></div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import { replaceMsg } from "@/assets/js/emoji.ts";

export default {
  name: "chatNew",
  data () {
    return {
      receive: null
    }
  },
  computed: {
    ...mapState({
      chatReceive: state => state.chat.chatReceive,
      chatContact: state => state.chat.chatContact
    }),
    contentMsg () {
      if (this.receive && this.receive.content) {
        let content = this.receive.content
        switch (content.type) {
          case 'text':
            content = replaceMsg(content.content)
            break
          case 'image':
            content = '[图片]'
            break
          case 'file':
            content = '[文件]'
            break
          case 'order':
            content = '[订单]'
            break
          case 'orderConfirm':
            content = '[订单确认]'
            break
          default:
            content = ''
            break
        }
        return content
      }
      return '暂无新消息'
    },
    name () {
      let j = this.chatContact.filter(o => {
        return this.receive.Id === o.Id
      })
      if (j.length) {
        return j[0].toUser.CompanyName
      }
      return ''
    }
  },
  watch: {
    chatReceive: {
      deep: true,
      immediate: true,
      handler (e) {
        if (e) {
          this.receive = e
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      set: 'chat/SET_CHAT_INFO',
      size: 'chat/SET_CHAT_SIZE'
    }),
    ...mapActions({
      clearUnRead: 'chat/clearUnRead'
    }),
    toSend () {
      this.size('mid')
      this.$router.push({ name: 'chat', params: { type: 1 } })
      if (this.receive) {
        this.set(this.receive.Id)
        this.clearUnRead(this.receive.Id)
        this.receive = null
      }
    }
  }
}
</script>

<style scoped lang="scss">
.chat-new-msg {
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  right: 10px;
  bottom: 10px;
  display: flex;
  flex-direction: row;
  z-index: 99;

  padding: 15px 0 15px 15px;
  line-height: 20px;
  color: #666;
  font-size: 12px;
  width: 235px;
  cursor: pointer;
}
</style>
