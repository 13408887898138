import api from '@/http'

export default {
  // 充值类型列表
  getPrepaidList: (data) => {
    return api({
      url: '/Prepaid/List',
      method: 'post',
      data
    })
  },
  // 添加充值类型
  addPrepaid: (data) => {
    return api({
      url: '/Prepaid/Insert',
      method: 'post',
      data
    })
  },
  // 删除充值类型
  delPrepaid: (id) => {
    return api({
      url: '/Prepaid/Delete',
      method: 'post',
      data: { Id: id }
    })
  },
  // 下架充值类型
  takeOffPrepaid: (id) => {
    return api({
      url: '/Prepaid/TakeOff',
      method: 'post',
      data: { Id: id }
    })
  },
  // 上架充值类型
  takeOnPrepaid: (id) => {
    return api({
      url: '/Prepaid/TakeOn',
      method: 'post',
      data: { Id: id }
    })
  },

  // 厂家统计信息
  facOverview: (data) => {
    return api({
      url: '/PrepaidFactory/Overview_Customer',
      method: 'post',
      data
    })
  },
  // 厂家的客户列表
  facPrepaidCustomer: (data) => {
    return api({
      url: '/PrepaidFactory/CustomerList',
      method: 'post',
      data
    })
  },
  // 厂家的消费列表
  facConsumeRecord: (data) => {
    return api({
      url: '/PrepaidFactory/ConsumeRecord',
      method: 'post',
      data
    })
  },
  // 厂家的客户详情
  facShopDetail: (data) => {
    return api({
      url: '/PrepaidFactory/ShopDetail',
      method: 'post',
      data
    })
  },
  // 厂家的所有客户充值记录
  facPrepaidRecord: (data) => {
    return api({
      url: '/PrepaidFactory/PrepaidRecord',
      method: 'post',
      data
    })
  },
  // 充值统计信息
  facPrepaidStatistics: (data) => {
    return api({
      url: '/PrepaidFactory/Overview_PrepaidRecord',
      method: 'post',
      data
    })
  },
  // 客户统计信息
  shopOverview: (data) => {
    return api({
      url: '/PrepaidShop/Overview',
      method: 'post',
      data
    })
  },
  // 客户统计信息-单个厂家
  shopOverview_Factory: (data) => {
    return api({
      url: '/PrepaidShop/Overview_Factory',
      method: 'post',
      data
    })
  },
  // 客户的厂家列表
  shopPrepaidFactory: (data) => {
    return api({
      url: '/PrepaidShop/FactoryList',
      method: 'post',
      data
    })
  },
  // 客户-厂家的充值记录
  shopPrepaidRecord_Factory: (data) => {
    return api({
      url: '/PrepaidShop/PrepaidRecord',
      method: 'post',
      data
    })
  },
  // 客户-厂家的消费记录
  shopPrepaidConsume_Factory: (data) => {
    return api({
      url: '/PrepaidShop/ConsumeRecord',
      method: 'post',
      data
    })
  },
  // 厂家预付款列表
  getFactoryPrepaidList: (data) => {
    return api({
      url: '/PrepaidShop/PrepaidList',
      method: 'post',
      data
    })
  },
  // 充值类型列表
  getMyPrepaidList: (data) => {
    return api({
      url: '/PrepaidFactory/PrepaidList',
      method: 'post',
      data
    })
  },
  // 厂家创建充值记录
  createPrepaidOrder: (data) => {
    return api({
      url: '/PrepaidOrder/Create',
      method: 'post',
      data
    })
  },
  // 充值订单详情
  getPrepaidOrderDetail: (data) => {
    return api({
      url: '/PrepaidOrder/Detail',
      method: 'post',
      data
    })
  },
  // 充值订单详情
  getPrepaidBalance: (data) => {
    return api({
      url: '/PrepaidFactory/GetBalance',
      method: 'post',
      data
    })
  },
}
