import api from '@/http'

export default {
  // 客户列表
  facCustomerList: (data) => {
    return api({
      url: '/CompanyCustomer/List',
      method: 'post',
      data
    })
  },
  // 客户概览
  facCustomerOverview: (data) => {
    return api({
      url: '/CompanyCustomer/Overview',
      method: 'post',
      data
    })
  },


  // 客户厂家列表
  shopFactoryList: (data) => {
    return api({
      url: '/CustomerFactory/FactoryList',
      method: 'post',
      data
    })
  },
  // 厂家订单概览
  shopCustomerOverview: (data) => {
    return api({
      url: '/CustomerFactory/FactoryOverview',
      method: 'post',
      data
    })
  },
}
