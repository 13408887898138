import axios from 'axios'
import store from '@/store'
import { ElMessage } from 'element-plus'//, ElLoading

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 对应国际化资源文件后缀
axios.defaults.headers['Content-Language'] = 'zh_CN'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: '/api',
  // 超时
  timeout: 100000
})
// 请求拦截
service.interceptors.request.use(config => {
  if (process.env.NODE_ENV === 'production') {
    config.url = 'https://api.66gg.vip/api' + config.url
    // config.url = 'http://192.168.1.6:8000/api' + config.url
    // config.url = config.url.replace(/^\/api/, '')
  }
  // showLoading()
  if (store.state.user.token) {
    config.headers['Authorization'] = 'Bearer ' + store.state.user.token
  }
  if (config.url.indexOf('Upload') > -1) {
    config.headers['Content-Type'] = 'multipart/form-data;'
  }
  return config
}, error => {
  // hideLoading()
  return Promise.reject(error)
})
// 响应拦截
service.interceptors.response.use(res => {
  // hideLoading()
  // 未设置状态码则默认成功状态为200
  const code = res.data.code || 0
  // 获取错误信息
  const msg = res.data.message
  if (code === 1000) {
    return res.data.data
  } else {
    ElMessage({
      message: msg,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject()
  }
}, error => {
  // hideLoading()
  let { message } = error;
  if (message === "Network Error") {
    message = "后端接口连接异常"
  } else if (message.includes("timeout")) {
    message = "系统接口请求超时"
  } else if (message.includes("Request failed with status code")) {
    let code = message.substr(message.length - 3)
    message = "系统接口" + code + "异常"
    if (code === '401') {
      location.href = '/login'
    }
  }
  ElMessage({
    message: message,
    type: 'error',
    duration: 5 * 1000
  })
  return Promise.reject(error)
})

// // 创建Loading实例
// let loadingInstance = null;
// const showLoading = () => {
//   loadingInstance = ElLoading.service({
//     // fullscreen: true,
//     // text: '加载中...',
//     // background: 'rgba(0, 0, 0, 0.3)'
//   });
// }
// const hideLoading = () => {
//   if (loadingInstance) {
//     loadingInstance.close();
//   }
// }
export default service
