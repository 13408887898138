import api from '@/http'

export default {
  // 创建订单
  createOrder: (data) => {
    return api({
      url: '/Order/Create',
      method: 'post',
      data
    })
  },
  // 订单详情
  getOrderDetail: (data) => {
    return api({
      url: '/Order/Detail',
      method: 'post',
      data
    })
  },
  // 订单详情--包含客户优惠券
  getViewOrderDetail: (data) => {
    return api({
      url: '/Order/ViewOrder',
      method: 'post',
      data
    })
  },
  // 客户确认订单
  confirmOrder: (data) => {
    return api({
      url: '/Order/ConfirmOrder',
      method: 'post',
      data
    })
  },
  // 厂家订单列表
  facOrderList: (data) => {
    return api({
      url: '/OrderFactory/OrderList',
      method: 'post',
      data
    })
  },
  // 厂家订单概览
  facOrderOverview: (data) => {
    return api({
      url: '/OrderFactory/Overview',
      method: 'post',
      data
    })
  },
  // 厂家客户订单概览
  facShopOrderDetail: (data) => {
    return api({
      url: '/OrderFactory/Overview_Shop',
      method: 'post',
      data
    })
  },
  // 客户订单列表
  shopOrderList: (data) => {
    return api({
      url: '/OrderShop/OrderList',
      method: 'post',
      data
    })
  },
  // 客户订单概览
  shopOrderOverview: (data) => {
    return api({
      url: '/OrderShop/Overview',
      method: 'post',
      data
    })
  },
  // 批量询价
  askOrder: (data) => {
    return api({
      url: '/Order/AskOrder',
      method: 'post',
      data
    })
  },
}
