import { ElMessage } from 'element-plus';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css'
import router from '@/router'
import store from './store'
import { readLocal } from "@/assets/js/common.ts";
import { role_router } from "@/router";

NProgress.configure({ showSpinner: false })

const whiteList = ['/login'] // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  let token = readLocal('USER', 'TOKEN') || null
  if (token) {
    if (to.path === '/login') {
      next()
      return
    }
    const hasUserType = store.state.user.userInfo && store.state.user.userInfo.RoleType
    if (hasUserType) {
      next()
    } else {
      try {
        const userInfo = await store.dispatch('user/getUserInfo')
        const { RoleType } = userInfo
        role_router['type_' + RoleType].forEach((item) => {
          router.addRoute(item)
        })
        next({ ...to, replace: true })
      } catch (error) {
        console.log(error)
        ElMessage.error({ message: error })
        next({
          path: '/login',
          query: { redirect: encodeURIComponent(to.fullPath) }
        })
        NProgress.done()
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
  // next()
})

router.afterEach(() => {
  NProgress.done()
  // document.querySelector("body").setAttribute("style", "overflow: auto !important;")
})
