import api from '@/http'

export default {
  // 获取详情
  getShopDetail: (data) => {
    return api({
      url: '/Company/GetDetail',
      method: 'post',
      data
    })
  },
  // 获取详情
  getMyShopDetail: () => {
    return api({
      url: '/Company/Detail',
      method: 'post',
      data: '{}'
    })
  },
  // 基本信息修改
  ShopUpdate: (data) => {
    return api({
      url: '/Company/ModifyInfo',
      method: 'post',
      data
    })
  },
  // 基本信息修改
  baseShopUpdate: (data) => {
    return api({
      url: '/Company/Base',
      method: 'post',
      data
    })
  },
  // 生产实力信息修改
  strengthShopUpdate: (data) => {
    return api({
      url: '/Company/Strength',
      method: 'post',
      data
    })
  },
  // 合作信息修改
  cooperationShopUpdate: (data) => {
    return api({
      url: '/Company/Cooperation',
      method: 'post',
      data
    })
  },
  // banner图提交
  fileShopUpdate: (data) => {
    return api({
      url: '/Company/Files',
      method: 'post',
      data
    })
  },
  // 价格表
  priceShopUpdate: (data) => {
    return api({
      url: '/Company/Price',
      method: 'post',
      data: {
        priceJson: JSON.stringify(data)
      }
    })
  },
  // 产品类型
  getProductType: () => {
    return api({
      url: '/CompanyProductCategory/List',
      method: 'post',
      data: '{}'
    })
  },
  productTypeAdd: (data) => {
    return api({
      url: '/CompanyProductCategory/Insert',
      method: 'post',
      data
    })
  },
  productTypeDel: (id) => {
    return api({
      url: '/CompanyProductCategory/Delete',
      method: 'post',
      data: {
        Id: id
      }
    })
  },
  // 单位产品提交
  addProduct: (data) => {
    return api({
      url: '/CompanyProduct/Insert',
      method: 'post',
      data
    })
  },
  // 单位产品删除
  deleteProduct: (id) => {
    return api({
      url: '/CompanyProduct/Delete',
      method: 'post',
      data: {
        Id: id
      }
    })
  },
  // 产品列表
  productList: () => {
    return api({
      url: '/CompanyProduct/List',
      method: 'post',
      data: '{}'
    })
  },
  // 单位产品提交
  addCase: (data) => {
    return api({
      url: '/CompanyCase/Insert',
      method: 'post',
      data
    })
  },
  // 单位产品提交
  deleteCase: (id) => {
    return api({
      url: '/CompanyCase/Delete',
      method: 'post',
      data: {
        Id: id
      }
    })
  },
  // 产品列表
  caseList: () => {
    return api({
      url: '/CompanyCase/List',
      method: 'post',
      data: '{}'
    })
  }
}
