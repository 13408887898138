import api from '@/api'

export default {
  namespaced: true,
  state: {
    osk: null,
    categorys: []
  },
  mutations: {
    SET_KEY: (state, data) => {
      state.osk = data
    },
    SET_Category: (state, data) => {
      state.categorys = data
    }
  },
  actions: {
    getOssKey ({ commit }) {
      api.getOssKey().then(res => {
        commit('SET_KEY', res)
      })
    },
    getCategorys ({ commit }) {
      api.getCategory().then(res => {
        commit('SET_Category', res.list)
      })
    }
  }
}
