import api from "@/api"

let outTimes = null

export default {
  namespaced: true,
  state: {
    chatSize: 'min', // 聊天框
    chatInfo: null, // 当前选中聊天人信息
    chatMsg: {}, // 聊天消息
    chatContact: [], // 最近聊天
    chatReceive: null, // 收到新的消息
    socket: null,
    userId: '',
    hasNewMsg: false, // 最小化新消息判断
    router: null
  },
  mutations: {
    SET_ROUTER: (state, router) => {
      state.router = router
    },
    SET_CHAT_SIZE: (state, data) => {
      state.chatSize = data
    },
    SET_CONTACT_LIST: (state, list) => {
      state.chatContact = list
    },
    SORT_CONTACT_LIST: (state, data) => {
      var contact = data
      //发送消息，聊天列表最近一条消息更新，聊天列表位置放到第一位.
      let items = state.chatContact.filter(o => {
        return o.Id === data.Id
      })
      if (items.length > 0) {
        contact = items[0]
        state.chatContact.splice(state.chatContact.indexOf(contact), 1);
      }
      state.chatContact.unshift(contact);
    },
    SET_CHAT_INFO: (state, id) => {
      let m = state.chatContact.filter(o => {
        return o.Id === id
      })
      state.chatInfo = m.length ? { ...m[0] } : null


      state.router.push({ name: 'chat' })
    },
    SET_UserId: (state, userId) => {
      state.userId = userId
    },
    SET_SOCKET: (state, socket) => {
      state.socket = socket
    },
    SET_NOTICE: (state) => {
      clearTimeout(outTimes)
      if (state.chatSize === 'min') {
        state.hasNewMsg = true
        outTimes = setTimeout(() => {
          state.hasNewMsg = false
        }, 3000)
      }
    },
    RECEIVE_MSG: (state, msg) => { // 从服务器发来的消息
      msg = JSON.parse(msg)
      state.chatReceive = msg

      let j = state.chatContact.filter(o => {
        return o.Id === msg.Id
      })
      if (j.length) {
        let chat = j[0]
        chat.message = {
          newMessageCount: chat.message.newMessageCount + 1,
          content: JSON.stringify(msg.content),
          lastTime: msg.CreateTime
        }
      } else {
        state.chatContact.push({
          Id: msg.Id,
          message: {
            newMessageCount: 1,
            content: JSON.stringify(msg.content),
            lastTime: msg.CreateTime
          },
          toUser: msg.toUser
        })
      }
      state.chatContact.sort((a, b) => b.message.lastTime.localeCompare(a.message.lastTime))
    }
  },
  actions: {
    // 创建聊天
    create_chat ({ state, commit, dispatch }, id) {
      if (state.chatInfo && state.chatInfo.toUser.Id === id) {
        commit('SET_CHAT_SIZE', 'mid')
      }
      else {
        let j = state.chatContact.filter(o => {
          return o.toUser.Id === id
        })
        if (j.length) {
          //如果已经在聊天列表中
          let chat = j[0]
          commit('SET_CHAT_INFO', chat.Id)
          dispatch('clearUnRead', chat.Id)
          commit('SET_CHAT_SIZE', 'mid')
        } else {
          //获取新的聊天，放在列表第一位
          api.createChat(id).then(room => {
            state.chatContact.unshift(room);
            commit('SET_CHAT_INFO', room.Id)
            commit('SET_CHAT_SIZE', 'mid')
          })
        }
      }
    },
    // websocket
    heart ({ state }) {
      if (state.timeoutObj) {
        clearTimeout(state.timeoutObj);
      }
      if (state.serverTimeoutObj) {
        clearTimeout(state.serverTimeoutObj);
      }
      //心跳检测
      state.timeoutObj = setTimeout(function () {
        //这里发送一个心跳，后端收到后，返回一个心跳消息，
        //onmessage拿到返回的心跳就说明连接正常
        if (state.socket) {
          state.socket.send("ping");
          state.serverTimeoutObj = setTimeout(function () {//如果超过一定时间还没重置，说明后端主动断开了
            state.socket.close();     //如果onclose会执行reconnect，我们执行ws.close()就行了.如果直接执行reconnect 会触发onclose导致重连两次
          }, 5000)
        }
      }, 10000)
    },
    // websocket
    create_socket ({ state, commit, dispatch }, uid) {
      if (state.socket) {
        return
      }
      let socket = new WebSocket('ws://127.0.0.1:5004/' + uid)
      // let socket = new WebSocket('wss://ws.66gg.vip/' + uid)
      socket.onopen = () => {

        dispatch('heart')
        console.log('连接')
      }
      socket.onmessage = (msg) => {
        dispatch('heart')
        if (msg.data !== 'pong') {
          commit('RECEIVE_MSG', msg.data) // 处理接收数据
          commit('SET_NOTICE') // 通知有新消息
        }
      }
      socket.onclose = (e) => {
        console.log('关闭连接:' + e.code)
        commit('SET_SOCKET', null)
      }
      socket.onerror = (err) => {
        console.log(err)
      }
      commit('SET_UserId', uid)
      commit('SET_SOCKET', socket)
    },
    // 发送消息到服务器
    send_msg_to_serve ({ state, commit }, data) {
      state.socket.send(JSON.stringify(data))
      return new Promise(resolve => {
        resolve(data.create_date)
        commit('SORT_CONTACT_LIST', data)
        var contact = state.chatContact.filter(o => { return o.Id === data.Id })[0]
        contact.message = {
          newMessageCount: 0,
          content: JSON.stringify(data.content),
          lastTime: data.create_date
        }
      })
    },
    // 清除未读
    clearUnRead ({ state }, chatId) {
      var contact = state.chatContact.filter(o => o.Id === chatId)
      if (contact.length > 0) {
        if (contact[0].message.newMessageCount > 0) {
          var data = { type: 'clear', chatId, userId: state.userId }
          state.socket.send(JSON.stringify(data))
          contact[0].message.newMessageCount = 0
          console.log('收到消息清除')
        }
      }
    },
    // 批量询价
    askOrder ({ state }, id) {
      var data = { type: 'askOrder', id }
      state.socket.send(JSON.stringify(data))
    }
  }
}
