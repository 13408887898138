import api from '@/http'

export default {
  // 登录
  login: (data) => {
    return api({
      url: '/Main/Login',
      method: 'post',
      data
    })
  },
  // 用户信息
  getUserInfo: () => {
    return api({
      url: '/Main/LoginInfo',
      method: 'post',
      data: '{}'
    })
  },
  // 用户列表
  getUserList: (data) => {
    return api({
      url: '/User/List',
      method: 'post',
      data
    })
  },
  // 添加用户
  addUser: (data) => {
    return api({
      url: '/User/Insert',
      method: 'post',
      data
    })
  },
  // 删除用户
  delUser: (id) => {
    return api({
      url: '/User/Delete',
      method: 'post',
      data: { Id: id }
    })
  },
  // 重置 密码
  resetPwd: (data) => {
    return api({
      url: '/User/Reset',
      method: 'post',
      data
    })
  },
  // 修改账号是否停用
  changeUserStatus: (data) => {
    return api({
      url: '/User/ChangeStatus',
      method: 'post',
      data
    })
  },
  // 头像修改
  updateAvatar: (avatar) => {
    return api({
      url: '/Main/ModifyAvatar',
      method: 'post',
      data: {
        avatar
      }
    })
  },
  // 修改密码
  updatePwd: (data) => {
    return api({
      url: '/Main/ChangePwd',
      method: 'post',
      data
    })
  },
  // 修改个人信息
  updateUser: (data) => {
    return api({
      url: '/Main/ModifyUser',
      method: 'post',
      data
    })
  },
  // 修改联系信息
  updateUserContact: (data) => {
    return api({
      url: '/Main/ModifyContact',
      method: 'post',
      data
    })
  }
}
