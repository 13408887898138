import api from '@/http'

export default {
  // 店家抵用金列表
  shopVoucherList: (data) => {
    return api({
      url: '/PaymentShop/VoucherList',
      method: 'post',
      data
    })
  },

  // 店家抵用金概况
  shopVoucherOverview: (data) => {
    return api({
      url: '/PaymentShop/VoucherOverview',
      method: 'post',
      data
    })
  },
  // 店家抵用金概况
  shopVoucherDetailOverview: (data) => {
    return api({
      url: '/PaymentShop/VoucherDetailOverview',
      method: 'post',
      data
    })
  },
  // 店家-厂家抵用金详情
  shopVoucherRecord: (data) => {
    return api({
      url: '/PaymentShop/VoucherRecord',
      method: 'post',
      data
    })
  },
}
