<template>
  <router-view v-slot="{Component}">
    <keep-alive>
      <component :is="Component" />
    </keep-alive>
  </router-view>
  <!--  <layout-wrap>-->
  <!--    <user-info @success="init"/>-->
  <!--    <ul>-->
  <!--      <li v-for="item in list" :key="item.Id">-->
  <!--        {{ item.Name }}-->
  <!--        <el-button @click="toSend(item)">发送消息</el-button>-->
  <!--      </li>-->
  <!--    </ul>-->
  <!--    <chat-box/>-->
  <!--  </layout-wrap>-->
</template>

<script>
// import {
//   mapState,
//   mapActions,
//   mapMutations
// } from 'vuex'
// import api from '@/api'
// import layoutWrap from '@/components/layout'
// import chatBox from '@/components/chat'
// import userInfo from '@/components/userInfo'
//
// export default {
//   name: 'App',
//   components: {
//     layoutWrap,
//     chatBox,
//     userInfo
//   },
//   data() {
//     return {
//       list: []
//     }
//   },
//   computed: {
//     ...mapState({
//       token: state => state.user.token,
//       user: state => state.user.userInfo,
//       socket: state => state.chat.socket,
//       chatShow: state => state.chat.chatShow
//     })
//   },
//   created() {
//     if (this.token) {
//       this.init()
//     }
//   },
//   watch: {
//     socket(v) {
//       if (!v && this.user.Id) {
//         this.createSocket(this.user.Id) // 重连
//       }
//     }
//   },
//   methods: {
//     ...mapMutations({
//       set: 'chat/SET_CHAT_INFO',
//       open: 'chat/SET_CHAT_SHOW',
//       size: 'chat/SET_CHAT_SIZE'
//     }),
//     ...mapActions({
//       getUserInfo: 'user/getUserInfo',
//       getChatContact: 'chat/get_contact_list',
//       createSocket: 'chat/create_socket',
//       getOss:'oss/getOssKey'
//     }),
//     init() {
//       this.getUserInfo().then(res => { // 获取个人信息
//         this.createSocket(res.Id)
//       })
//       this.getUserList()
//       this.getChatContact() // 最近聊天
//       this.getOss()
//     },
//     toSend(item) {
//       if (!this.chatShow) {
//         this.open(true)
//       }
//       this.size('mid')
//       api.createChat(item.Id).then(cid => {
//         this.getChatContact().then(() => {
//           this.set(cid)
//         })
//       })
//     },
//     getUserList() {
//       api.getUserList().then(res => {
//         this.list = res.list
//       })
//     }
//   }
// }
</script>

<style lang="scss">
.el-table .el-table__cell {
  text-align: center !important;
}
</style>
