<template>
  <layout-wrap>
    <div @click="setChatSize" class="mainDiv">
      <router-view v-slot="{Component}">
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </div>

    <!--新消息提示-->
    <chat-new />
    <audio id="notification-sound">
      <source src="/ding.mp3" type="audio/mpeg">
    </audio>
  </layout-wrap>
</template>

<script>
import {
  mapState,
  mapActions,
  mapMutations
} from 'vuex'
import layoutWrap from '@/components/layout'
import chatNew from '@/components/chat/chatNew'
export default {
  name: 'layout-page',
  components: {
    layoutWrap,
    chatNew
  },
  data () {
    return {
      isOnline: navigator.onLine,
      timer: null
    }
  },
  computed: {
    ...mapState({
      token: state => state.user.token,
      user: state => state.user.userInfo,
      socket: state => state.chat.socket,
      chatReceive: state => state.chat.chatReceive,
      chatSize: state => state.chat.chatSize
    })
  },
  created () {
    if (this.token) {
      this.init()
    } else {
      this.$router.replace('/login')
    }
    this.setRouter(this.$router)
  },
  mounted () {
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
    window.addEventListener('online', this.handleNetworkChange);
    window.addEventListener('offline', this.handleNetworkChange);
  },
  watch: {
    handleNetworkChange () {
      this.isOnline = navigator.onLine;
      if (this.isOnline) {
        console.log('网络已重新连接');
        // 执行其他操作
      } else {
        console.log('网络连接已断开');
        // 执行其他操作
      }
    },
    socket (v) {
      if (!v && this.user.Id) {
        this.createSocket(this.user.Id) // 重连
      }
    },
    chatReceive: {
      deep: true,
      immediate: true,
      handler (e) {
        if (e) {
          // this.showNotification("New message", "message");
          var sound = document.getElementById("notification-sound");
          if (sound) {
            sound.play();
          }
          window.focus();
          if (document.hidden) {
            //标题栏闪动
            var defaultTitle = document.title;
            this.timer = setInterval(function () {
              var title = document.title;
              if (document.hidden) {
                if (/你有新消息/.test(title) == false) {
                  document.title = '【你有新消息】';
                } else {
                  document.title = defaultTitle;
                }
              } else {
                this.isReceive = false;
                document.title = defaultTitle;
              }
            }, 500);
          }
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      size: 'chat/SET_CHAT_SIZE',
      setRouter: 'chat/SET_ROUTER',
    }),
    ...mapActions({
      createSocket: 'chat/create_socket',
      getOss: 'common/getOssKey',
      getCategorys: 'common/getCategorys'
    }),
    setChatSize () {
      if (this.chatSize != 'min') {
        this.size('min')
      }
    },
    init () {
      this.createSocket(this.user.Id)
      this.getOss()
      this.getCategorys()
    },
    handleVisibilityChange () {
      if (document.visibilityState === "visible") {
        // 当前页面可见
        if (this.timer) {
          clearInterval(this.timer)
        }
      }
    },
    showNotification (title, message) {
      // Check if the browser supports notifications
      if (!("Notification" in window)) {
        console.warn("This browser does not support desktop notifications.");
        return;
      }

      // Request permission to show notifications
      if (Notification.permission !== "granted") {
        Notification.requestPermission().then(function (permission) {
          if (permission !== "granted") {
            console.warn("User has denied permission to show notifications.");
            return;
          }
        });
      }

      // Create notification
      var notification = new Notification(title, {
        body: message,
        icon: "notification-icon.png"
      });

      // Close notification after 5 seconds
      setTimeout(function () {
        notification.close();
      }, 5000);
    }
  }
}
</script>

<style scoped lang="scss">
.mainDiv {
  height: calc(100vh - 50px);
  min-width: 1100px;
  overflow-x: auto;
}
</style>
