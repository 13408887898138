import api from '@/http'

export default {
  // 获取所有店铺
  getShopMapList: () => {
    return api({
      url: '/Map/ShopList',
      method: 'post',
      data: '{}'
    })
  },
  // 获取所有店铺
  getFactoryMapList: () => {
    return api({
      url: '/Map/FactoryList',
      method: 'post',
      data: '{}'
    })
  },
  // 获取所有店铺
  getAllFactoryMapList: () => {
    return api({
      url: '/Map/FactoryAllList',
      method: 'post',
      data: '{}'
    })
  },
}
