const emojiList = [{
    url: '/image/emoji/a.png',
    alt: '啊'
},
    {
        url: '/image/emoji/aini.png',
        alt: '爱你'
    },
    {
        url: '/image/emoji/aixin.png',
        alt: '爱心'
    },
    {
        url: '/image/emoji/aixin2.png',
        alt: '色'
    },
    {
        url: '/image/emoji/baiyan.png',
        alt: '白眼'
    },
    {
        url: '/image/emoji/bianbian.png',
        alt: '便便'
    },
    {
        url: '/image/emoji/biti.png',
        alt: '鼻涕'
    },
    {
        url: '/image/emoji/bizui.png',
        alt: '闭嘴'
    },
    {
        url: '/image/emoji/bushufu.png',
        alt: '不舒服'
    },
    {
        url: '/image/emoji/dajing.png',
        alt: '大惊'
    },
    {
        url: '/image/emoji/danao.png',
        alt: '大闹'
    },
    {
        url: '/image/emoji/daxiao.png',
        alt: '大笑'
    },
    {
        url: '/image/emoji/dianzan.png',
        alt: '点赞'
    },
    {
        url: '/image/emoji/emo.png',
        alt: '恶魔'
    },
    {
        url: '/image/emoji/en.png',
        alt: '嗯~'
    },
    {
        url: '/image/emoji/esi.png',
        alt: '饿死'
    },
    {
        url: '/image/emoji/fadai.png',
        alt: '发呆'
    },
    {
        url: '/image/emoji/fankun.png',
        alt: '犯困'
    },
    {
        url: '/image/emoji/fankun2.png',
        alt: '打哈欠'
    },
    {
        url: '/image/emoji/feiwen.png',
        alt: '飞吻'
    },
    {
        url: '/image/emoji/fendou.png',
        alt: '奋斗'
    },
    {
        url: '/image/emoji/fennu.png',
        alt: '愤怒'
    },
    {
        url: '/image/emoji/ganga.png',
        alt: '尴尬'
    },
    {
        url: '/image/emoji/ganmao.png',
        alt: '感冒'
    },
    {
        url: '/image/emoji/guaiwu.png',
        alt: '怪物'
    },
    {
        url: '/image/emoji/hanyan.png',
        alt: '汗颜'
    },
    {
        url: '/image/emoji/haochi.png',
        alt: '好吃'
    },
    {
        url: '/image/emoji/heng.png',
        alt: '哼'
    },
    {
        url: '/image/emoji/huaixiao.png',
        alt: '坏笑'
    },
    {
        url: '/image/emoji/huqi.png',
        alt: '呼气'
    },
    {
        url: '/image/emoji/jingkong.png',
        alt: '惊恐'
    },
    {
        url: '/image/emoji/jingsong.png',
        alt: '惊悚'
    },
    {
        url: '/image/emoji/jingya.png',
        alt: '惊讶'
    },
    {
        url: '/image/emoji/kaixin.png',
        alt: '开心'
    },
    {
        url: '/image/emoji/keshui.png',
        alt: '瞌睡'
    },
    {
        url: '/image/emoji/ku.png',
        alt: '酷'
    },
    {
        url: '/image/emoji/kulou.png',
        alt: '骷髅'
    },
    {
        url: '/image/emoji/leng.png',
        alt: '冷'
    },
    {
        url: '/image/emoji/lengku.png',
        alt: '冷酷'
    },
    {
        url: '/image/emoji/liubixie.png',
        alt: '流鼻血'
    },
    {
        url: '/image/emoji/liuhan.png',
        alt: '流汗'
    },
    {
        url: '/image/emoji/liukoushui.png',
        alt: '流口水'
    },
    {
        url: '/image/emoji/liulei.png',
        alt: '流泪'
    },
    {
        url: '/image/emoji/maren.png',
        alt: '骂人'
    },
    {
        url: '/image/emoji/mengbi.png',
        alt: '懵逼'
    },
    {
        url: '/image/emoji/mianwubiaoqing.png',
        alt: '面无表情'
    },
    {
        url: '/image/emoji/nanguo.png',
        alt: '难过'
    },
    {
        url: '/image/emoji/outu.png',
        alt: '呕吐'
    },
    {
        url: '/image/emoji/qie.png',
        alt: '企鹅'
    },
    {
        url: '/image/emoji/renzhe.png',
        alt: '忍者'
    },
    {
        url: '/image/emoji/santiaoxian.png',
        alt: '三条线'
    },
    {
        url: '/image/emoji/shengqi.png',
        alt: '生气'
    },
    {
        url: '/image/emoji/shimo.png',
        alt: '什么'
    },
    {
        url: '/image/emoji/shoushang.png',
        alt: '受伤'
    },
    {
        url: '/image/emoji/shuai.png',
        alt: '衰'
    },
    {
        url: '/image/emoji/shuixing.png',
        alt: '睡醒'
    },
    {
        url: '/image/emoji/shuizhuo.png',
        alt: '睡着'
    },
    {
        url: '/image/emoji/siliao.png',
        alt: '死了'
    },
    {
        url: '/image/emoji/tanchi.png',
        alt: '贪吃'
    },
    {
        url: '/image/emoji/taoyan.png',
        alt: '讨厌'
    },
    {
        url: '/image/emoji/taoyan2.png',
        alt: '很讨厌'
    },
    {
        url: '/image/emoji/tianshi.png',
        alt: '天使'
    },
    {
        url: '/image/emoji/tiaopi.png',
        alt: '调皮'
    },
    {
        url: '/image/emoji/wuliao.png',
        alt: '无聊'
    },
    {
        url: '/image/emoji/xianwen.png',
        alt: '献吻'
    },
    {
        url: '/image/emoji/xiaochulei.png',
        alt: '笑出泪'
    },
    {
        url: '/image/emoji/xiaodiaodaya.png',
        alt: '笑掉大牙'
    },
    {
        url: '/image/emoji/xiaolian.png',
        alt: '笑脸'
    },
    {
        url: '/image/emoji/xiasi.png',
        alt: '吓死'
    },
    {
        url: '/image/emoji/xieyan.png',
        alt: '斜眼'
    },
    {
        url: '/image/emoji/xingxingyan.png',
        alt: '星星眼'
    },
    {
        url: '/image/emoji/xinsui.png',
        alt: '心碎'
    },
    {
        url: '/image/emoji/xiong.png',
        alt: '凶'
    },
    {
        url: '/image/emoji/yiwen.png',
        alt: '疑问'
    },
    {
        url: '/image/emoji/youling.png',
        alt: '幽灵'
    },
    {
        url: '/image/emoji/yousiliao.png',
        alt: '又死了'
    },
    {
        url: '/image/emoji/yun.png',
        alt: '晕'
    },
    {
        url: '/image/emoji/zhadan.png',
        alt: '炸弹'
    },
    {
        url: '/image/emoji/zhutou.png',
        alt: '猪头'
    },
    {
        url: '/image/emoji/ziya.png',
        alt: '呲牙'
    }
]
export default emojiList

export function replaceMsg(str) {
    let replacedStr = str.replace(/\[([^(\]|[)]*)\]/g, item => {
        let face = emojiList.filter(o => {
            return ('[' + o.alt + ']') === item
        })
        if (face.length) {
            return '<img src="' + face[0].url + '" style="width:24px;height:24px">'
        }
    })
    return replacedStr
}
