// 本地存储
export function saveLocal(id, key, val) {
  let store = window.localStorage._store_
  if (!store) {
    store = {}
    store[id] = {}
  } else {
    store = JSON.parse(store)
    if (!store[id]) {
      store[id] = {}
    }
  }
  store[id][key] = val
  window.localStorage._store_ = JSON.stringify(store)
}

export function readLocal(id, key, defaults) {
  let store = window.localStorage._store_
  if (!store) {
    return defaults
  }
  store = JSON.parse(store)[id]
  if (!store) {
    return defaults
  }
  let ret = store[key]
  return ret || defaults
}

export function removeLocal(id, key) {
  let store = window.localStorage._store_
  if (store) {
    store = JSON.parse(store)
    if (store[id]) {
      if (key) {
        if (store[id][key]) {
          delete store[id][key]
        }
      } else {
        delete store[id]
      }
      window.localStorage._store_ = JSON.stringify(store)
    }
  }
}

export function formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + ''
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : ('00' + str).substr(('' + str).length)
      )
    }
  }
  return fmt
}

// 时间戳处理
export function timeDay() {
  const today = new Date()
  const year = today.getFullYear()
  const month = (today.getMonth() + 1).toString().padStart(2, '0')
  const day = today.getDate().toString().padStart(2, '0')
  const nTime = `${year}${month}${day}`

  return nTime
}

// 时间戳处理
export function timeStamp(time, isShort = false) {
  let curTime = new Date()
  let cTimes = curTime.getTime()
  let cD = curTime.getDate()
  // if (!['number', 'string'].includes(typeof time)) return ''
  // time = typeof time === 'number' ? time : time.replace(/-/g, '/')
  let proTime = new Date(time)
  let pTimes = proTime.getTime()
  let pD = proTime.getDate()
  let pHours = proTime.getHours()
  let pMin = proTime.getMinutes()
  let ph = pHours > 9 ? String(pHours) : '0' + pHours
  let pm = pMin > 9 ? String(pMin) : '0' + pMin
  let pW = [
    '星期日',
    '星期一',
    '星期二',
    '星期三',
    '星期四',
    '星期五',
    '星期六',
  ][proTime.getDay()]

  let nTime = ''
  let diffTimes = cTimes - pTimes
  let day = 24 * 60 * 60 * 1000
  let week = 7 * day
  if (diffTimes > 0) {
    if (diffTimes < 24 * 60 * 60 * 1000) {
      nTime = ph + ':' + pm
      if (cD > pD) {
        nTime = '昨天 ' + nTime
      }
    } else if (diffTimes <= week) {
      nTime = pW + ' ' + ph + ':' + pm
    } else {
      if (isShort) {
        nTime = formatDate(proTime, 'yy/MM/dd')
      } else {
        nTime = formatDate(proTime, 'yyyy年MM月dd hh:mm')
      }
    }
  }
  return nTime
}
// 判断guid
export function isGuid(str) {
  const guidRegex =
    /^[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12}$/
  return guidRegex.test(str)
}
// 生成唯一id
export function getMiniUuid() {
  let S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  let guid = () => {
    return S4() + S4()
  }
  return guid()
}

// 生成唯一id
export function getUuid() {
  let S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  let guid = () => {
    return (
      S4() +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      S4() +
      S4()
    )
  }
  return guid()
}

// 生成唯一id
export function sortObjectArray(objArr, keyArr, type) {
  for (var x = 0; x < objArr.length; x++) {
    objArr[x].oldIndex = x
  }
  if (type != undefined && type != 'asc' && type != 'desc') {
    return 'error'
  }
  var order = 1
  if (type != undefined && type == 'desc') {
    order = -1
  }
  var key = keyArr[0]
  objArr.sort(function (objA, objB) {
    if (objA[key] > objB[key]) {
      return order
    } else if (objA[key] < objB[key]) {
      return 0 - order
    } else {
      if (objA.oldIndex > objB.oldIndex) {
        return order
      } else if (objA.oldIndex < objB.oldIndex) {
        return 0 - order
      } else {
        return 0
      }
    }
  })

  for (var i = 1; i < keyArr.length; i++) {
    key = keyArr[i]
    for (var y = 0; y < objArr.length; y++) {
      objArr[y].oldIndex = y
    }
    objArr.sort(function (objA, objB) {
      for (var j = 0; j < i; j++) {
        if (objA[keyArr[j]] != objB[keyArr[j]]) {
          if (objA.oldIndex > objB.oldIndex) {
            return order
          } else if (objA.oldIndex < objB.oldIndex) {
            return 0 - order
          } else {
            return 0
          }
        }
      }
      if (objA[key] > objB[key]) {
        return order
      } else if (objA[key] < objB[key]) {
        return 0 - order
      } else {
        if (objA.oldIndex > objB.oldIndex) {
          return order
        } else if (objA.oldIndex < objB.oldIndex) {
          return 0 - order
        } else {
          return 0
        }
      }
    })
  }
  return objArr
}

// k,m大小转换
export function formatBytes(a, b = 2) {
  // b:四舍五入保留小数位数
  if (0 === a) return '0 B'
  let c = 1024
  let e = ['B', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y']
  let f = Math.floor(Math.log(a) / Math.log(c))
  return parseFloat((a / Math.pow(c, f)).toFixed(b)) + ' ' + e[f]
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function (...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}
